import $ from "jquery";

// Floors and Flats
import floor1 from "../../img/levels/1_patro.png";
import floor2 from "../../img/levels/2_patro.png";
import floor3 from "../../img/levels/3_patro.png";
import floor4 from "../../img/levels/4_patro.png";
import floor5 from "../../img/levels/5_patro.png";

import flat101 from "../../img/flats/floor1/101_byt.png";
import flat102 from "../../img/flats/floor1/102_byt.png";
import flat103 from "../../img/flats/floor1/103_byt.png";
import flat104 from "../../img/flats/floor1/104_byt.png";
import retail1 from "../../img/flats/floor1/Retail1.png";
import retail2 from "../../img/flats/floor1/Retail2.png";

import flat201 from "../../img/flats/floor2/201_byt.png";
import flat202 from "../../img/flats/floor2/202_byt.png";
import flat203 from "../../img/flats/floor2/203_byt.png";
import flat204 from "../../img/flats/floor2/204_byt.png";
import flat205 from "../../img/flats/floor2/205_byt.png";
import flat206 from "../../img/flats/floor2/206_byt.png";
import flat207 from "../../img/flats/floor2/207_byt.png";
import flat208 from "../../img/flats/floor2/208_byt.png";
import flat209 from "../../img/flats/floor2/209_byt.png";

import flat301 from "../../img/flats/floor3/301_byt.png";
import flat302 from "../../img/flats/floor3/302_byt.png";
import flat303 from "../../img/flats/floor3/303_byt.png";
import flat304 from "../../img/flats/floor3/304_byt.png";
import flat305 from "../../img/flats/floor3/305_byt.png";
import flat306 from "../../img/flats/floor3/306_byt.png";
import flat307 from "../../img/flats/floor3/307_byt.png";
import flat308 from "../../img/flats/floor3/308_byt.png";
import flat309 from "../../img/flats/floor3/309_byt.png";
import flat310 from "../../img/flats/floor3/310_byt.png";

import flat401 from "../../img/flats/floor4/401_byt.png";
import flat402 from "../../img/flats/floor4/402_byt.png";
import flat403 from "../../img/flats/floor4/403_byt.png";
import flat404 from "../../img/flats/floor4/404_byt.png";
import flat405 from "../../img/flats/floor4/405_byt.png";
import flat406 from "../../img/flats/floor4/406_byt.png";
import flat407 from "../../img/flats/floor4/407_byt.png";
import flat408 from "../../img/flats/floor4/408_byt.png";
import flat409 from "../../img/flats/floor4/409_byt.png";
import flat410 from "../../img/flats/floor4/410_byt.png";

import flat501 from "../../img/flats/floor5/501_byt.png";
import flat502 from "../../img/flats/floor5/502_byt.png";
import flat503 from "../../img/flats/floor5/503_byt.png";
import flat504 from "../../img/flats/floor5/504_byt.png";
import flat505 from "../../img/flats/floor5/505_byt.png";
import flat506 from "../../img/flats/floor5/506_byt.png";
import flat507 from "../../img/flats/floor5/507_byt.png";
// /Floors and Flats

// Hover on flat
const defaultImgFloor1 = floor1;
const defaultImgFloor2 = floor2;
const defaultImgFloor3 = floor3;
const defaultImgFloor4 = floor4;
const defaultImgFloor5 = floor5;

const flats1 = [
  { htmlId: "#flat101", imgPath: flat101 },
  { htmlId: "#flat102", imgPath: flat102 },
  { htmlId: "#flat103", imgPath: flat103 },
  { htmlId: "#flat104", imgPath: flat104 },
  { htmlId: "#retail1", imgPath: retail1 },
  { htmlId: "#retail2", imgPath: retail2 },
];

const flats2 = [
  { htmlId: "#flat201", imgPath: flat201 },
  { htmlId: "#flat202", imgPath: flat202 },
  { htmlId: "#flat203", imgPath: flat203 },
  { htmlId: "#flat204", imgPath: flat204 },
  { htmlId: "#flat205", imgPath: flat205 },
  { htmlId: "#flat206", imgPath: flat206 },
  { htmlId: "#flat207", imgPath: flat207 },
  { htmlId: "#flat208", imgPath: flat208 },
  { htmlId: "#flat209", imgPath: flat209 },
];

const flats3 = [
  { htmlId: "#flat301", imgPath: flat301 },
  { htmlId: "#flat302", imgPath: flat302 },
  { htmlId: "#flat303", imgPath: flat303 },
  { htmlId: "#flat304", imgPath: flat304 },
  { htmlId: "#flat305", imgPath: flat305 },
  { htmlId: "#flat306", imgPath: flat306 },
  { htmlId: "#flat307", imgPath: flat307 },
  { htmlId: "#flat308", imgPath: flat308 },
  { htmlId: "#flat309", imgPath: flat309 },
  { htmlId: "#flat310", imgPath: flat310 },
];

const flats4 = [
  { htmlId: "#flat401", imgPath: flat401 },
  { htmlId: "#flat402", imgPath: flat402 },
  { htmlId: "#flat403", imgPath: flat403 },
  { htmlId: "#flat404", imgPath: flat404 },
  { htmlId: "#flat405", imgPath: flat405 },
  { htmlId: "#flat406", imgPath: flat406 },
  { htmlId: "#flat407", imgPath: flat407 },
  { htmlId: "#flat408", imgPath: flat408 },
  { htmlId: "#flat409", imgPath: flat409 },
  { htmlId: "#flat410", imgPath: flat410 },
];

const flats5 = [
  { htmlId: "#flat501", imgPath: flat501 },
  { htmlId: "#flat502", imgPath: flat502 },
  { htmlId: "#flat503", imgPath: flat503 },
  { htmlId: "#flat504", imgPath: flat504 },
  { htmlId: "#flat505", imgPath: flat505 },
  { htmlId: "#flat506", imgPath: flat506 },
  { htmlId: "#flat507", imgPath: flat507 },
];

for (const assoc of flats1) {
  $(assoc.htmlId)
    .on("mouseover", function (e) {
      $("#pills2-1").html(
        "<img src='" + assoc.imgPath + "' class='img-fluid'>"
      );
    })
    .on("mouseout", function (e) {
      $("#pills2-1").html(
        "<img src='" + defaultImgFloor1 + "' class='img-fluid'>"
      );
    });
}

for (const assoc of flats2) {
  $(assoc.htmlId)
    .on("mouseover", function (e) {
      $("#pills2-2").html(
        "<img src='" + assoc.imgPath + "' class='img-fluid'>"
      );
    })
    .on("mouseout", function (e) {
      $("#pills2-2").html(
        "<img src='" + defaultImgFloor2 + "' class='img-fluid'>"
      );
    });
}

for (const assoc of flats3) {
  $(assoc.htmlId)
    .on("mouseover", function (e) {
      $("#pills2-3").html(
        "<img src='" + assoc.imgPath + "' class='img-fluid'>"
      );
    })
    .on("mouseout", function (e) {
      $("#pills2-3").html(
        "<img src='" + defaultImgFloor3 + "' class='img-fluid'>"
      );
    });
}

for (const assoc of flats4) {
  $(assoc.htmlId)
    .on("mouseover", function (e) {
      $("#pills2-4").html(
        "<img src='" + assoc.imgPath + "' class='img-fluid'>"
      );
    })
    .on("mouseout", function (e) {
      $("#pills2-4").html(
        "<img src='" + defaultImgFloor4 + "' class='img-fluid'>"
      );
    });
}

for (const assoc of flats5) {
  $(assoc.htmlId)
    .on("mouseover", function (e) {
      $("#pills2-5").html(
        "<img src='" + assoc.imgPath + "' class='img-fluid'>"
      );
    })
    .on("mouseout", function (e) {
      $("#pills2-5").html(
        "<img src='" + defaultImgFloor5 + "' class='img-fluid'>"
      );
    });
}
// /Hover on flat
