import $ from "jquery";
import "popper.js";
import "bootstrap";

//import "lightgallery";
import "./src/headerOnScroll";
import "./src/hamburger";
import "./src/scrollToHash";
import "./src/swiperInit";
import "./src/bootstrapPills";
import "./src/floorFlats";
import "./src/reserve";
import "./src/seznamCode";

import "./src/map";
