import $ from "jquery";
// Bootstrap Navs Pill Tab
$("#pills-tab a").on("click", function (e) {
  e.preventDefault();
  $(this).tab("show");
  // Second Tab
  let siteContentID = $(this).data("side");
  $(".tabs-imgs").removeClass("show active");
  $(siteContentID).addClass("show active");
  // /Second Tab
});
// /Bootstrap Navs Pill Tab
