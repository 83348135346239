import $ from "jquery";
// Scroll to Hash
//$("a[href^='#']").on("click", function (e) {
$(".anchor").on("click", function (e) {
  $("html, body").animate(
    {
      scrollTop: $($(this).attr("href")).offset().top - 70,
    },
    1000
  );
  return false;
});
if ($(window.location.hash).length > 1) {
  $("html, body").animate(
    {
      scrollTop: $(window.location.hash).offset().top - 70,
    },
    1000
  );
}
// /Scroll to Hash
