import $ from "jquery";

// User Clicked on Flat --> REZERVOVAT
$(function () {
  $(".btnMessage").on("click", function (e) {
    e.preventDefault();
    let id = $(this).attr("id");

    if (id.startsWith("R")) {
      document.getElementById("message").value =
        "Dobrý den, mám zájem o retail " + id;
    } else {
      document.getElementById("message").value =
        "Dobrý den, mám zájem o byt " + id;
    }
  });
});
// /User Clicked on Flat
