import ourMarker from "../../img/map/markerSpojovaci.svg";

// Create the script tag, set the appropriate attributes
let script = document.createElement("script");
const APIKEY = "AIzaSyDTjF-NBiXurQNEI7jA5QQ4WAFB4oHhj5c";
script.src =
  "https://maps.googleapis.com/maps/api/js?key=" + APIKEY + "&callback=initMap";
script.async = true;

// Attach your callback function to the `window` object
window.initMap = function () {
  // JS API is loaded and available
  console.log("Nacteni mapy");

  const uluru = { lat: 50.100144, lng: 14.492132 };

  //Vykresli mapu
  const map = new google.maps.Map(document.getElementById("map"), {
    zoom: 16,
    center: uluru,
    disableDefaultUI: true,
  });

  const ownMarker = ourMarker;

  // The marker, positioned at Uluru
  const marker = new google.maps.Marker({
    position: uluru,
    map: map,
    icon: ownMarker,
  });
};

// Append the 'script' element to 'head'
document.head.appendChild(script);
