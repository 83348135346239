import Swiper from "swiper/bundle";

// Swiper initialisation
const swiperHero = new Swiper(".swiper-hero", {
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

const swiperNews = new Swiper(".swiper-news", {
  slidesPerView: 1,
  spaceBetween: 30,
  // centeredSlides: true,

  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },

  breakpoints: {
    768: {
      slidesPerView: 2,
      // centeredSlides: true,
    },
    1200: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  },
});

// /Swiper
