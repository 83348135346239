import $ from "jquery";
// Header on scroll
if (window.scrollY !== 0) {
  //user reload page  and he is not on the top of the page
  $("header").addClass("active");
}
$(window).on("scroll", function () {
  if ($(window).scrollTop() > 10) {
    $("header").addClass("active");
  } else {
    $("header").removeClass("active");
  }
});
// /Header on scroll
